import { useEffect, useState } from 'react';

import { ISelectedPlan, QR_CODE_PRODUCT_ID } from 'components';
import { IDBDowngradeRequest, useGetMuriquiProject, useToast, useGetActivePlans, useGetTamarinProject } from 'hooks';
import isEmpty from 'lodash.isempty';
import { useRouter } from 'next/router';
import { IDBTamarinProject } from 'types';

import PlanContext from './PlanContext';
import { PlanContextProps } from './PlanContext.interfaces';


export function PlanProvider({ children }: PlanContextProps) {
  const { handleToastError } = useToast();
  const {
    query: { projectId: queryProjectId },
  } = useRouter();

  const [projectId, setProjectId] = useState<string | undefined>();
  const [planName, setPlanName] = useState<string | undefined>();
  const [isFreePlan, setIsFreePlan] = useState<boolean>(true);
  const [activeProject, setActiveProject] = useState<IDBTamarinProject>();
  const [activePlan, setActivePlan] = useState<ISelectedPlan>();
  const [currentRemainingScans, setCurrentRemainingScans] = useState<number>(100);
  const [planScanAmount, setPlanScanAmount] = useState<number>(100);
  const [downgradeRequest, setDowngradeRequest] = useState<IDBDowngradeRequest | undefined>();

  const { handleGetMuriquiProject } = useGetMuriquiProject({
    fetchPolicy: 'no-cache',
    onCompleted: ({ project }) => {
      const {
        customerDetail: { subscriptions },
      } = project;
      if (!subscriptions || subscriptions.length === 0) return;
      const [subscription] = subscriptions;
      const { downgradeRequest: request } = subscription;
      if (!request) return;
      setDowngradeRequest(request);
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetTamarinProject } = useGetTamarinProject({
    fetchPolicy: 'no-cache',
    onCompleted: ({ project }) => {

      if (!isEmpty(project) && project.isActive) {
        setActiveProject(project);
        setCurrentRemainingScans(project.remainingScans);
      } else {
        setCurrentRemainingScans(100);
      }

    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleGetActivePlans } = useGetActivePlans({
    onCompleted: ({ plans: activePlans = [] }) => {
      setActivePlan({
        plan: activePlans.find(({ productId }) => productId === QR_CODE_PRODUCT_ID),
      });
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  useEffect(() => {
    const { plan } = activePlan ?? {};
    if (!activePlan || !plan) {
      setIsFreePlan(true);
      setPlanScanAmount(100);
      setPlanName('Free');

    } else {
      const { name, value } = plan;
      setIsFreePlan(name.toLocaleLowerCase() === 'free');
      setPlanScanAmount(value);
      setPlanName(name);
    }
  }, [activePlan]);

  useEffect(() => {
    if (!projectId) return;

    void handleGetMuriquiProject({
      projectId,
    });

    void handleGetActivePlans({
      projectId,
      product: 'QUICKCODES',
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (activeProject || !projectId) return;

    void handleGetTamarinProject({
      id: projectId,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProject, projectId]);


  useEffect(() => {
    setProjectId(queryProjectId as string);
  }, [queryProjectId])



  return (
    <PlanContext.Provider
      value={{
        activePlan,
        downgradeRequest,
        isFreePlan,
        planName,
        planScanAmount,
        remainingScans: currentRemainingScans,
      }}
    >
      {children}
    </PlanContext.Provider>
  );
}
