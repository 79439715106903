import 'backpack.css';

import '@netfront/ui-library/dist/esm/css/index.css';
import '@netfront/ui-library/dist/css/global.css';
import '@netfront/ui-library/dist/css/helpers.css';
import '@netfront/ui-library/dist/css/typography.css';

import '@netfront/ekardo-content-library/dist/esm/css/index.css';

import '../styles/components.css';
import '../styles/fonts.css';
import '../styles/globals.css';
import '../styles/theme.css';
import '../styles/utils.css';

import { useEffect, useState } from 'react';

import { useMediaQuery } from '@netfront/common-library';
import { ContentPageProvider, useGoogleAnalytics } from '@netfront/ekardo-content-library';
import { useUser } from '@netfront/gelada-identity-library';
import { CookieConsent, Icons } from '@netfront/ui-library';
import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';
import LogRocket from 'logrocket';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Toaster } from 'react-hot-toast';

import { FacebookPixel } from '../components';
import { QUICKCODES_COOKIE_CONSENT_MESSAGE } from '../constants';
import { PermissionProvider, CachingEntitiesProvider, DashboardProvider, UserProvider, PlanProvider } from '../contexts';
import { IUseUserType } from '../utils/userType';

function App({ Component, pageProps }: AppProps & { Component: React.ComponentType }) {
  const googleAnalyticsMeasurementId = String(process.env.REACT_APP_GOOGLE_ANALYTICS);
  const isGoogleAnalyticsDebugMode = String(process.env.REACT_APP_GOOGLE_ANALYTICS_DEBUG_MODE).toUpperCase() === 'Y';

  const { trackPageView, trackUser } = useGoogleAnalytics();
  const { events } = useRouter();
  const { getUser } = useUser();

  const isMobile = useMediaQuery({
    size: 'small',
  });

  const [gtagConfigParams, setGtagConfigParams] = useState<ReturnType<typeof omitBy>>();
  const [hasKanziTool, setHasKanziTool] = useState<boolean>(false);

  const user = getUser();

  if (typeof window !== 'undefined' && window.location.hostname !== 'localhost') {
    LogRocket.init('sfzpuh/quickcodes');

    if (user) {
      const { id: userId, firstName, lastName, email } = user as unknown as IUseUserType;

      LogRocket.identify(String(userId), {
        name: `${firstName} ${lastName}`,
        email: email,
      });
    }
  }

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      trackPageView(url);
      trackUser();
    };

    // When the component is mounted, subscribe to router changes
    // and log those page views
    events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      events.off('routeChangeComplete', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, user]);

  useEffect(() => {
    if (gtagConfigParams || !user) {
      return;
    }

    setGtagConfigParams(() =>
      omitBy(
        {
          debug_mode: isGoogleAnalyticsDebugMode ? true : undefined,
          user_id: String(user.id),
        },
        isNil,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gtagConfigParams, user]);

  useEffect(() => {
    if (!hasKanziTool) return;

    const kanziTools = [...document.getElementsByClassName('c-kanzi')];

    if (kanziTools.length === 0) return;

    kanziTools.forEach((kanziTool) => {
      const kanziToolElement = kanziTool as HTMLElement;

      if (isMobile) {
        kanziToolElement.style.display = 'none';
      } else {
        kanziToolElement.style.display = 'block';
      }
    });

  }, [isMobile, hasKanziTool]);

  // we have to wait until the kanzi script has injected the html before we can determine whether to hide it
  useEffect(() => {
    const interval = setInterval(() => {
      const kanziTools = [...document.getElementsByClassName('c-kanzi')];

      if (kanziTools.length > 0) {
        setHasKanziTool(true);
        clearInterval(interval);
      }
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div id="root">
      {gtagConfigParams && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
            gtag('config', '${googleAnalyticsMeasurementId}'${
              Object.keys(gtagConfigParams).length ? `, ${JSON.stringify(gtagConfigParams)}` : ''
            });
          `,
          }}
        />
      )}

      <CachingEntitiesProvider>
        <PlanProvider>
          <UserProvider>
            <PermissionProvider>
              <DashboardProvider>
                <div>
                  <Toaster
                    position="top-center"
                    reverseOrder={false}
                    toastOptions={{
                      duration: 3000,
                    }}
                  />
                </div>
                <FacebookPixel />
                <Icons />
                <CookieConsent cookieConsentMessage={QUICKCODES_COOKIE_CONSENT_MESSAGE} />
                {
                  <ContentPageProvider>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <Component {...pageProps} />
                  </ContentPageProvider>
                }
              </DashboardProvider>
            </PermissionProvider>
          </UserProvider>
        </PlanProvider>
      </CachingEntitiesProvider>
    </div>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
