import { GET_TAMARIN_PROJECT_QUERY } from './useGetTamarinProject.graphql';
import {
  IGetTamarinProjectQueryGraphQLResponse,
  IGetTamarinProjectQueryVariables,
  IHandleGetTamarinProjectParams,
  IUseGetTamarinProject,
  IUseGetTamarinProjectOptions,
} from './useGetTamarinProject.interfaces';

import { useTamarinLazyQuery } from '../../apollo';

const useGetTamarinProject = (options?: IUseGetTamarinProjectOptions): IUseGetTamarinProject => {
  const { fetchPolicy, onCompleted, onError, query, token } = options ?? ({} as IUseGetTamarinProjectOptions);

  const [executeGetTamarinProject, { loading: isLoading }] = useTamarinLazyQuery<
    IGetTamarinProjectQueryGraphQLResponse,
    IGetTamarinProjectQueryVariables
  >({
    options: {
      fetchPolicy: fetchPolicy ?? 'cache-and-network',
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          project: { getTamarinProject: project },
        } = data;

        onCompleted({
          project,
        });
      },
      onError,
    },
    query: query ?? GET_TAMARIN_PROJECT_QUERY,
    token,
  });

  const handleGetTamarinProject = async ({ id }: IHandleGetTamarinProjectParams) => {
    await executeGetTamarinProject({
      variables: {
        id,
      },
    });
  };

  return {
    handleGetTamarinProject,
    isLoading,
  };
};

export { useGetTamarinProject };
