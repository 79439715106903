import { useContext, useEffect, useState } from 'react';

import { useDomain, useIdentitySiteUrls } from '@netfront/gelada-identity-library';

import { CachingEntitiesContext } from 'contexts/CachingEntitiesContext';
import { PermissionContext } from 'contexts/PermissionContext';

import DashboardContext from './DashboardContext';
import { DashboardContextProps, IDashboardItemsList } from './DashboardContext.interfaces';



export function DashboardProvider({ children }: DashboardContextProps) {
  const { project } = useContext(CachingEntitiesContext);
  const { hasPermission } = useContext(PermissionContext);
  const { isDomainReady } = useDomain();
  const [dashboardItemsList, setDashboardItemsList] = useState<IDashboardItemsList[]>([]);
  const [dashboardLink, setDashboardLink] = useState<string>('');

  const { getBaseUrl: getIdentitySiteBaseUrl } = useIdentitySiteUrls({
    environment: process.env.REACT_APP_ENVIRONMENT,
    port: process.env.REACT_APP_IDENTITY_SITE_LOCAL_PORT,
  });


  useEffect(() => {
    if (!(project && isDomainReady)) {
      return;
    }

    const { id: projectId, organisation } = project;

    const hasEditPermission = hasPermission(String(projectId), 'EDIT');


    const quickcodesBaseUrl = `/dashboard/${String(organisation.key)}/${String(projectId)}`;
    const identityBaseUrl = getIdentitySiteBaseUrl();

    const cardItems: IDashboardItemsList[] = [
      {
        id: 'reporting',
        description: 'Project reporting page',
        href: `${quickcodesBaseUrl}/reporting`,
        image: '',
        iconId: 'id_units_tab_icon',
        title: 'Reporting',
        isInternal: true,
      },
      {
        id: 'support',
        description: 'Support page',
        href: `/contact`,
        image: '',
        isVisible: hasEditPermission,
        iconId: 'id_support_icon',
        title: 'Support',
        isInternal: true,
      },
      {
        id: 'resources',
        description: 'Resources page',
        href: `/docs`,
        image: '',
        isVisible: hasEditPermission,
        iconId: 'id_resources_icon',
        title: 'Resources',
        isInternal: true,
      },
      {
        id: 'user',
        description: 'user page',
        href: `${identityBaseUrl}/project/${String(projectId)}/user`,
        image: '',
        isVisible: hasEditPermission,
        iconId: 'id_users_icon',
        title: 'Users',
      },


    ];

    setDashboardItemsList(cardItems.filter(card => {
      card.subItems = card.subItems?.filter(({isVisible = true}) => isVisible) ?? [];
      return card.isVisible ?? true;
    }));
    setDashboardLink(quickcodesBaseUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, isDomainReady]);


  return (
    <DashboardContext.Provider
      value={{
        dashboardItems: dashboardItemsList,
        dashboardLink,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}
